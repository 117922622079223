interface PhaseAssets {
  title: string;
  description: string;
}

export const getPhaseAssets = (phaseId: number) => {
  const PhaseAssets: Record<number, PhaseAssets> = {
    40: {
      title: "5-a-side",
      description:
        "Introducing four straight Gameweeks of 5-a-side action. Compete for the best rank in the first FPL Challenge Event!",
    },
    41: {
      title: "International Series",
      description:
        "Celebrate the diversity of the Premier League. Compete in three different challenges using the best players from three different regions!",
    },
    42: {
      title: "Test Lab",
      description:
        "Shape the future of Fantasy Premier League by joining in on our experiments. This series we are running four tests that focus on earning points through different players and playstyles. What new FPL assets become must-haves?",
    },
  };
  // check PhaseAssets has a key for the phase otherwise return null
  return Object.hasOwn(PhaseAssets, phaseId) ? PhaseAssets[phaseId] : null;
};
